import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Está página no se encuentra" />
    <h1>¡Vaya! Está página no se encuentra.</h1>
    <p>Parece que no se encontró nada aquí.</p>
  </Layout>
)

export default NotFoundPage
